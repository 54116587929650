@import url("./fonts.css");

/* ATTENTION: Most styling is done inline with Material-UIs sx prop. If you’re looking to restyle an element, it’s probably best to look at its JSX first. */

:root {
  --bg-color: #00364a;
  --text-color: white;
  --slider-color: #d9d9d9;
  --primary-dark: #00aaa2;
  --primary-light: #66ccc7;
  --secondary-dark: #72cff4;
  --secondary-light: #9cddf7;
  /* --secondary-dark: #ff705f;
  --secondary-light: #ff9b8e;  */ /* <-- ISH-Manufaktur-Rot */
}

body {
  background: var(--bg-color);
  font-family: "Inter";
  color: white;
}


.app {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}


.evaluation-container {
  box-sizing: border-box;
  background-color: white;
  max-width: 650px;
  border: 3px solid var(--primary-light);
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
}


.ishSliderContainer {
  max-width: 650px;
  margin: auto 100px;
}


@media (min-width: 960px) {
  #logo-link {
    margin-left: 86px;
    box-sizing: content-box;
  }
  .ishSliderContainer,
  .evaluation-container {
    margin: auto auto;
  }

}



.app-header {
  width: 100%;
  padding: 0;
  margin: 0;
}

.page-title {
  margin-top: 30px;
  color: var(--primary-light);
  font-size: 2.5em;
}

.page-subtitle {
  color: var(--primary-light);
  margin-top: -15px;
  margin-bottom: -30px;
}

.app-header > h5 {
  margin-top: -10px;
}

.page-header, .page-description {
  max-width: 650px;
  margin: 20px auto;
}

.page-description {
  text-align: center;
  font-style: italic;
}

.slider-description {
  text-align: center;
  font-style: italic;
  padding-bottom: 20px;
  margin-top: -20px;
}

.slider-note {
  margin-bottom: 45px;
}

.group-heading {
  font-weight: 
}

.slider-heading {
  color: var(--text-color);
  margin-bottom: 40px;
  margin-top: 60px;
  font-weight: lighter;
  /* text-align: left; */
  /* margin-left: -50px; */
}

/* Donation Modal */
#customized-dialog-title {
  color: black;
}

#donation-button {
  background-color: var(--primary-dark);
}

#donation-button:visited {
  color: white;
}

.slider-wrapper-box {
  min-height: 240px;
  min-width: 220px;
}

.MuiCardContent-root {
  padding-bottom: 16px !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Rubik";
  color: var(--text-color);
  text-align: center;
}

h2 {
  
}

.MuiSlider-root {
  color: var(--slider-color) !important;
  padding-bottom: 40px !important;
}

.MuiSlider-root:hover {
  color: var(--slider-color);
}

.MuiSlider-root .MuiSlider-thumb {
  width: 30px;
  height: 30px;
  color: var(--primary-dark);
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 0.5s ease;
}

.MuiSlider-root .MuiSlider-valueLabel {
  background-color: var(--primary-light);
  opacity: 0.9;
  color: black;
  border-radius: 8px;
  transition: background-color 0.5s ease;
}

.MuiSlider-valueLabelLabel {
  display: inline-block !important;
  white-space: normal;
}

.MuiSlider-valueLabelCircle {
  min-width: 120px;
  max-width: 140px;
  min-height: 16px;
  max-height: 64px;
  text-align: center;
}

.MuiSlider-root .MuiSlider-valueLabel:before {
  /* margin-left: 50px; */
  background-color: var(--primary-light);
  transition: background-color 0.3s ease;
}

/* Color slider thumb & value label red on hover */





.ishSliderContainer:hover
  .MuiBox-root
  .MuiSlider-root
  .MuiSlider-thumb
  .MuiSlider-valueLabel,
.ishSliderContainer:hover
  .MuiBox-root
  .MuiSlider-root
  .MuiSlider-thumb
  .MuiSlider-valueLabel::before,
.ishSliderContainer:active
  .MuiBox-root
  .MuiSlider-root
  .MuiSlider-thumb
  .MuiSlider-valueLabel {
  background-color: var(--secondary-light);
  transition: background-color 0.5s ease;
}

.ishSliderContainer:hover .MuiBox-root .MuiSlider-root .MuiSlider-thumb {
  color: var(--secondary-dark);
}



p {
  font-family: "Inter" !important;
  word-break: break-word;
}

a {
  color: var(--primary-light);
}

a:hover {
  color: var(--secondary-light) !important;
}

a:visited {
  color: var(--primary-dark);
}

#logo {
  width: auto;
  height: 100%;  
}

#slogan {
  margin-right: 50px;
}



/* Impressum & Datenschutz */

.legal-button {
  color: var(--primary-light) !important;
}
.legal-button:hover {
  color: var(--secondary-dark) !important;
}

.legal-text {
  color: var(--text-color);
  max-width: 650px;
}

.legal-text > h2 {
  text-align: left;
}

.legal-text > h6 {
  text-align: center;
  padding: 0;
  margin-top: -8px;
}
