/* inter-100 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: local(""), url("./fonts/inter-v11-latin-100.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/inter-v11-latin-100.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-200 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  src: local(""), url("./fonts/inter-v11-latin-200.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/inter-v11-latin-200.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-300 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("./fonts/inter-v11-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/inter-v11-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-regular - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./fonts/inter-v11-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/inter-v11-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-500 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("./fonts/inter-v11-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/inter-v11-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-600 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("./fonts/inter-v11-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/inter-v11-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-700 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./fonts/inter-v11-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/inter-v11-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-800 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("./fonts/inter-v11-latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/inter-v11-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-900 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: local(""), url("./fonts/inter-v11-latin-900.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/inter-v11-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-300 - latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("./fonts/rubik-v20-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/rubik-v20-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-regular - latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./fonts/rubik-v20-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/rubik-v20-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-500 - latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("./fonts/rubik-v20-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/rubik-v20-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-700 - latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./fonts/rubik-v20-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/rubik-v20-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-600 - latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("./fonts/rubik-v20-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/rubik-v20-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-800 - latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("./fonts/rubik-v20-latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/rubik-v20-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-300italic - latin */
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 300;
  src: local(""), url("./fonts/rubik-v20-latin-300italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/rubik-v20-latin-300italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-500italic - latin */
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 500;
  src: local(""), url("./fonts/rubik-v20-latin-500italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/rubik-v20-latin-500italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
